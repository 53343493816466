import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Context } from 'context/Context';
import HeliosClient from 'context/Helios';
import { useLocation, useParams } from 'react-router-dom';
import { ActionType, AppContext, Photo, Validation } from 'context/interfaces';
import Breadcrumbs from 'components/Breadcrumbs';
import * as Sentry from '@sentry/browser';

import { deleteImageFromBucket, other, uploadPhoto } from '../../context/Utils';

import WarningScreen from '../../components/camera/Warning';
import imageCompression from 'browser-image-compression';

import { ReactComponent as HelpIcon } from 'assets/icons/info.svg';

import '@connect-assistance/connect-react-components-lib/dist/index.css';
import '../../assets/styles/screens/photos/Camera.scss';
import info from '../../assets/icons/i-info.svg';
import './CameraScreen.scss';
import ConfirmButton from 'components/common/ConfirmButton';
import HeimdallClient from 'context/Heimdall';
import Button from 'components/common/Button';
import Portal from 'components/portal';
import { PhotoId, getTooltipContents } from '@connect-assistance/connect-react-components-lib';
import { isBlurryImg } from 'shared/utils/blurryImage';
import { Tooltip } from '@material-ui/core';
import { Snackbar } from '@mui/material';
import { Alert, AlertColor } from '@mui/lab';

interface CameraProps {
	screenIndex: number;
}

// Inspired from: http://jsfiddle.net/4cwpLvae/
function ImageUploadThumbnail({
	photo,
	onChange,
	onPreviewClick,
	onHelpClick,
	setIsExample,
	photoPreview,
}: {
	photo: Photo;
	onChange: any;
	onPreviewClick: any;
	onHelpClick: any;
	setIsExample: any;
	photoPreview: any;
}): JSX.Element {
	if (photo.awsUrl) {
		return (
			<img
				src={photo.awsUrl}
				className="image-preview-thumbnail"
				alt={photo.description}
				onClick={() => onPreviewClick(photo)}
			/>
		);
	} else if (photoPreview && photoPreview.length > 0) {
		const previewPhoto = photoPreview.find((preview: any) => preview.id === photo.id);
		if (previewPhoto) {
			return (
				<img
					src={previewPhoto.url}
					className="image-preview-thumbnail"
					alt={photo.description}
					onClick={() => onPreviewClick(photo)}
				/>
			);
		}
	}
	const htmlId = `photo-${photo.id}`;
	return (
		<div className="image-upload-thumbnail">
			<div
				className="custom-file-upload"
				onClick={() => {
					setIsExample(false);
					onHelpClick(photo);
				}}
			>
				<div className="image-container">
					<img src={photo.placeholder} className="camera-plus-icon" alt="camera icon" />
					{photo.description.length > 50 && (
						<Tooltip title={photo.description} classes={{ popper: 'custom-tooltip' }}>
							<img src={info} className="info-icon" alt="info icon" />
						</Tooltip>
					)}
				</div>
				<span title={photo.description}>{photo.description}</span>
			</div>
			<div
				className="ayuda"
				onClick={() => {
					setIsExample(true);
					onHelpClick(photo);
				}}
			>
				<HelpIcon />
				<span>Ejemplo</span>
			</div>
			{(photo.useGallery === undefined || (photo.useGallery !== undefined && !photo.useGallery)) && (
				<input
					id={htmlId}
					type="file"
					accept="image/*;capture=camera"
					capture="environment"
					onChange={(e) => onChange(photo, e)}
				/>
			)}

			{photo.useGallery && (
				<input
					id={htmlId}
					type="file"
					accept="image/*,video/*"
					capture="environment"
					onChange={(e) => onChange(photo, e)}
				/>
			)}
		</div>
	);
}

function HelpModal({ photo, onClose, isExample }: { photo: Photo; onClose: any; isExample: boolean }): JSX.Element {
	const contents = getTooltipContents(photo.id as PhotoId);
	const defaultContents = {
		title: 'Agrega una nueva foto',
		imgUrl: other,
		paragraph: 'En esta sección puedes agregar fotos adicionales que consideres necesarias.',
	};

	const closeModal = (htmlId: string) => {
		onClose();
		if (!isExample) {
			document.getElementById(htmlId)?.click();
		}
	};

	const htmlId = `photo-${photo.id}`;

	return (
		<Portal>
			<div className="photo-help-container" onClick={onClose}>
				<div
					className="modal"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<h2>{contents?.title || defaultContents.title}</h2>
					<img src={contents?.imgUrl || defaultContents.imgUrl} alt={contents?.title || defaultContents.title} />
					{contents?.paragraph || <p>{defaultContents.paragraph}</p>}
					<Button
						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						onClick={(e) => {
							closeModal(htmlId);
						}}
					>
						Entendido
					</Button>
				</div>
			</div>
		</Portal>
	);
}

function FullScreenPreview({ photo, onRemove, onExit }: { photo: Photo; onRemove: any; onExit: any }): JSX.Element {
	return (
		<div className="full-screen-preview">
			<img className="preview-photo" src={photo.awsUrl} alt={photo.description + ' preview'} />
			<div className="action-buttons">
				<Button danger onClick={onRemove}>
					Eliminar
				</Button>
				<Button onClick={onExit}>Ok</Button>
			</div>
		</div>
	);
}

export const savePhoto = async (
	event: any,
	setValidationError: any,
	photo: Photo,
	eventId: number,
	serviceId: string,
	inspectionId: string
): Promise<string | void> => {
	const imageFile = event.target.files[0];
	const compressedFile = await imageCompression(imageFile, {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	});

	const dataURI = await imageCompression.getDataUrlFromFile(compressedFile);
	const awsUrl = await uploadPhoto({ ...photo!, dataUrl: dataURI }, eventId, serviceId, inspectionId);
	const valid = await checkValidation(photo, awsUrl, setValidationError, imageFile);
	if (!valid && awsUrl) await deleteImageFromBucket({ imageUrl: awsUrl, eventId, serviceId, inspectionId });
	if (awsUrl && valid) return awsUrl;
};

/** Makes requests to Heimdall per photo.validations */
export const checkValidation = async (
	photo: Photo,
	awsUrl: any,
	setValidationError: any,
	originalImg: File
): Promise<boolean> => {
	// Check if the image is blurry before newtwork based validations
	const isBlurry = await isBlurryImg(originalImg);
	if (isBlurry) {
		setValidationError('IS_BLURRY');
		return !isBlurry;
	}

	const client = new HeimdallClient();
	const promises: Promise<any>[] = photo.validations.map((validation) => {
		switch (validation) {
			case Validation.HAS_DOCUMENT:
				return client.isDocument(awsUrl);
			case Validation.HAS_VEHICLE:
				return client.isVehicle(awsUrl);
			case Validation.EXTRACT_PLATE:
				return client.extractPlate(awsUrl);
			case Validation.EXTRACT_VIN:
				return client.extractVin(awsUrl);
			case Validation.HAS_LEGIBLE_LICENSE:
				return client.hasLegibleLicense(awsUrl);
			default:
				console.warn('Unknown validation', validation);
				return Promise.resolve(null);
		}
	});
	const results = await Promise.all(promises);

	// using .some to "short-circuit" and just set error to first found
	const foundErrors = results.some((result, index) => {
		if (result === false || result === null) {
			setValidationError(photo.validations[index]);
			return true; // found error
		}
		return false;
	});
	return !foundErrors;
};

const CameraScreen = ({ screenIndex }: CameraProps): ReactElement => {
	const ctx = useContext(Context) as AppContext;
	const { photos } = ctx;
	const { id } = useParams<{ id: string }>();
	const [validationError, setValidationError] = useState<Validation | null | 'IS_BLURRY'>(null);
	const [previewPhoto, setPreviewPhoto] = useState<Photo | null>(null);
	const [helpModalId, setHelpModalId] = useState<Photo | null>(null);
	const [isExample, setIsExample] = useState<boolean>(false);
	const [uploadedPhotoUrls, setUploadedPhotoUrls] = useState<{ id: string; url: string }[]>([]);
	const [uploadPhotoMessage, setUploadPhotoMessage] = useState<{
		showAlertPhoto: boolean;
		alertMessagePhoto: string;
		alertTypePhoto: AlertColor | undefined;
	}>({
		showAlertPhoto: false,
		alertMessagePhoto: '',
		alertTypePhoto: 'success',
	});
	const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
	const [typeMessage, setTypeMessage] = useState<AlertColor | undefined>('success');
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [finallyUpload, setFinallyUpload] = useState<boolean>(false);

	const {
		state: { sectionId },
	} = useLocation<{ sectionId: string }>();

	const onAddPhoto = async (photoId: string, awsUrl: string): Promise<any> => {
		ctx.dispatch({
			type: ActionType.ADD_PHOTO,
			data: { photoId, awsUrl },
		});
		return new HeliosClient().saveInspection(ctx, id);
	};
	const removePhoto = async (photoId: string): Promise<void> => {
		const photo = ctx.photos.find((photo) => photo.id === photoId);
		if (!photo) {
			return;
		}
		setUploadedPhotoUrls((prevPhotos) => prevPhotos.filter((preview) => preview.id !== photoId));

		await deleteImageFromBucket({
			eventId: +ctx.eventId!,
			inspectionId: ctx.inspectionId!,
			imageUrl: photo.awsUrl!,
			serviceId: ctx.serviceId!,
		});

		ctx.dispatch({ type: ActionType.DELETE_PHOTO, data: photoId });
		new HeliosClient().saveInspection(ctx, id);
		setPreviewPhoto(null);
	};

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	const onChange = async (photo: Photo, event: any) => {
		if (!event.target.files) {
			return;
		}

		const imageFile = event.target.files[0];
		const reader = new FileReader();
		setUploadPhotoMessage({
			...uploadPhotoMessage,
			showAlertPhoto: true,
			alertMessagePhoto: 'Cargando imagen ...',
			alertTypePhoto: 'warning',
		});

		reader.onload = async (e) => {
			if (!e.target || !e.target.result) {
				return;
			}

			const dataUrl = e.target.result as string;

			const img = new Image();
			img.src = dataUrl;

			img.onload = () => {
				setUploadedPhotoUrls((prevUploadedPhotoUrls) => [...prevUploadedPhotoUrls, { id: photo.id, url: dataUrl }]);
			};
		};

		reader.readAsDataURL(imageFile);
		const interval = changeMessageLoading();
		setFinallyUpload(false);
		try {
			const awsUrl = await savePhoto(event, setValidationError, photo, ctx.eventId!, ctx.serviceId!, ctx.inspectionId!);
			if (awsUrl) {
				await onAddPhoto(photo.id, awsUrl);
			}
		} catch (error) {
			Sentry.captureException(error);
		} finally {
			clearInterval(interval);
			setFinallyUpload(true);
		}
	};

	const handleWarningButton = (): void => {
		setValidationError(null);
	};

	const changeMessageLoading = () => {
		const messages: string[] = ['Validando Fotografía', 'Subiendo Fotografía', 'Procesando Fotografía'];
		let indexMessage = 0;
		const interval = setInterval(() => {
			if (indexMessage < messages.length - 1) {
				indexMessage += 1;
			}
		}, 5000);

		return interval;
	};

	const filteredPhotos = photos.filter((photo) => photo.sectionId === sectionId);
	const canContinue = filteredPhotos.every((photo) => (photo.required ? Boolean(photo.awsUrl) : true));

	useEffect(() => {
		setShowSnackbar(false);
		if (uploadPhotoMessage.alertTypePhoto && uploadPhotoMessage.alertMessagePhoto) {
			setShowSnackbar(true);
			setTypeMessage(uploadPhotoMessage.alertTypePhoto);
			setAlertMessage(uploadPhotoMessage.alertMessagePhoto);
		}
	}, [uploadPhotoMessage]);

	const handleSnackBarClearMessage = () => {
		setShowSnackbar(false);
		setFinallyUpload(false);
	};

	useEffect(() => {
		if (validationError && uploadedPhotoUrls) {
			setUploadedPhotoUrls([]);
			setShowSnackbar(false);
			setFinallyUpload(false);
		}
	}, [validationError]);

	useEffect(() => {
		if (finallyUpload && uploadedPhotoUrls.length > 0) {
			setUploadPhotoMessage({
				...uploadPhotoMessage,
				showAlertPhoto: true,
				alertMessagePhoto: 'Se finalizo el proceso de cargue de imágenes ...',
				alertTypePhoto: 'success',
			});
		}
	}, [finallyUpload]);

	useEffect(() => {
		if (showSnackbar && typeMessage === 'success') {
			const timer = setTimeout(() => {
				handleSnackBarClearMessage();
			}, 5000);

			return () => clearTimeout(timer);
		}
	}, [showSnackbar, typeMessage]);

	return (
		<div className="simple-camera-screen">
			<Breadcrumbs currentStep={screenIndex} />

			<div className="photos-container">
				{filteredPhotos.map((photo) => (
					<ImageUploadThumbnail
						key={photo.id}
						photo={photo}
						onChange={onChange}
						onPreviewClick={setPreviewPhoto}
						onHelpClick={setHelpModalId}
						setIsExample={setIsExample}
						photoPreview={uploadedPhotoUrls}
					/>
				))}
			</div>
			<ConfirmButton disabled={!canContinue} to={'/' + id + '/photo/sections'} />
			{validationError && (
				<div className="warnings">
					{validationError === Validation.HAS_DOCUMENT && (
						<WarningScreen
							title="Documento no detectado"
							message="Parece que la foto que tomaste no corresponde a un documento o se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
					{validationError === Validation.HAS_VEHICLE && (
						<WarningScreen
							title="Vehículo no detectado"
							message="Parece que la foto que tomaste no corresponde a un vehículo o se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
					{validationError === Validation.EXTRACT_PLATE && (
						<WarningScreen
							title="Placa no detectada"
							message="Parece que la foto que tomaste no corresponde a una placa o se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
					{validationError === Validation.EXTRACT_VIN && (
						<WarningScreen
							title="VIN no detectada"
							message="Parece que la foto que tomaste no corresponde a un VIN o se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
					{validationError === Validation.HAS_LEGIBLE_LICENSE && (
						<WarningScreen
							title="Licencia no detectada"
							message="Parece que la foto que tomaste no corresponde a un licencia o se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
					{validationError === 'IS_BLURRY' && (
						<WarningScreen
							title="Imagen borrosa"
							message="Parece que la foto que tomaste se encuentra borrosa"
							handleClick={handleWarningButton}
						/>
					)}
				</div>
			)}
			{previewPhoto && (
				<FullScreenPreview
					photo={previewPhoto}
					onRemove={() => removePhoto(previewPhoto.id)}
					onExit={() => setPreviewPhoto(null)}
				/>
			)}
			{helpModalId && <HelpModal photo={helpModalId} onClose={() => setHelpModalId(null)} isExample={isExample} />}

			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={showSnackbar}
			>
				{typeMessage !== 'success' ? (
					<Alert severity={typeMessage}>
						<div dangerouslySetInnerHTML={{ __html: alertMessage }} />
					</Alert>
				) : (
					<Alert onClose={() => handleSnackBarClearMessage()} severity={typeMessage}>
						<div dangerouslySetInnerHTML={{ __html: alertMessage }} />
					</Alert>
				)}
			</Snackbar>
		</div>
	);
};

export default CameraScreen;
