import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, DispatchFunction, Brands, IndividualStateKey } from '../../context/interfaces';
import ProgressBar from '../../components/ProgressBar';
import { completeSectionGTM, initializeSectionGTM } from '../../shared/utils/gooogleTagManager';
import { BRAND, urlIdRewrite } from 'shared/utils';
import '../../assets/styles/screens/insured/Narration.scss';
import TextInput from '../../components/ui/TextInput';

const MIN_NARRATION_LENGTH = 10;
const INPUT_NARRATION = 'narrationData';
const INPUT_ADITIONAL = 'aditionalData';

export const updateText = (
	event: React.ChangeEvent<HTMLTextAreaElement>,
	dispatch: DispatchFunction,
	stateKey: IndividualStateKey
): void => {
	if (event.target.id === INPUT_NARRATION) {
		dispatch({ type: ActionType.SET_NARRATION_TEXT, data: { value: event.target.value, key: stateKey } });
	} else if (event.target.id === INPUT_ADITIONAL) {
		dispatch({ type: ActionType.SET_NARRATION_ADITIONAL_TEXT, data: { value: event.target.value, key: stateKey } });
	}
};

interface Props {
	to: string;
	stateKey: IndividualStateKey;
	screenKey: any;
}

const NarrationScreen = (props: Props): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const locationPath = useLocation();
	const { dispatch, insured, injured, location, SinisterType } = context;
	const { id } = useParams<{ id: string }>();
	const [nextScreen, setNextScreen] = useState(urlIdRewrite(props.to, id));
	const [initLoadGT, setInitLoadGT] = useState(true);
	const setText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => updateText(event, dispatch, props.stateKey);
	const narrationData = props.stateKey === IndividualStateKey.INSURED ? insured.narration : injured.narration;
	const aditionalData = insured?.narrationAdditional || '';
	const isLesionesHomicidios = SinisterType === 'Lesiones' || SinisterType === 'Homicidios';
	const canContinue = isLesionesHomicidios
		? narrationData?.trim().length > MIN_NARRATION_LENGTH &&
		  isLesionesHomicidios &&
		  insured?.injuries !== undefined &&
		  insured.injuries.trim().length > 0
		: narrationData?.trim().length > MIN_NARRATION_LENGTH;
	const [showAditionalText, setShowAditionalText] = useState(false);
	const [textData] = useState(narrationData);

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(context, locationPath.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, context, locationPath.pathname]);

	useEffect(() => {
		if (
			[Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands) &&
			location.selectedLocation === 'default'
		) {
			setNextScreen('/' + id + '/insured/damages');
		} else if ([Brands.SEGUROS_MUNDIAL].includes(BRAND as Brands) && isLesionesHomicidios) {
			setNextScreen('/' + id + '/photo/sections');
		}
	}, [location, id]);

	const handleConfirm = (): void => {
		completeSectionGTM(context, locationPath.pathname);
		new HeliosClient().saveInspection(context, id);
	};

	const disabledNarration = (): boolean => {
		return (isCRACOccount() && textData?.trim().length > 0) as boolean;
	};

	const isCRACOccount = (): boolean => {
		return (
			[Brands.ASSA, Brands.LAFISE, Brands.OCEANICA, Brands.QUALITAS].includes(BRAND as Brands) ||
			(BRAND === Brands.SEGUROS_MUNDIAL && !isLesionesHomicidios)
		);
	};

	const handleAditionalClick = (): void => {
		setShowAditionalText(true);
	};

	const handleOnChange = (value: string): void => {
		context.dispatch({
			type: ActionType.SET_INJURIES,
			data: value,
		});
	};

	return (
		<div className="narration-screen">
			{props.screenKey && <Breadcrumbs currentStep={props.screenKey} />}
			<div className="container">
				<ProgressBar screenIndex={props.screenKey} />
				<Header title="Escriba una breve descripción del accidente" />
				<div className="body">
					<textarea
						id={INPUT_NARRATION}
						disabled={disabledNarration()}
						placeholder="Describa los hechos"
						rows={5}
						value={narrationData}
						onChange={setText}
					/>
					{isLesionesHomicidios && (
						<TextInput
							key="Lesiones sufridas en el accidente"
							label="Lesiones sufridas en el accidente"
							type="text"
							inputMode=""
							inputSize={200}
							readonly={false}
							required={true}
							value={insured?.injuries}
							message=""
							onChange={(event) => handleOnChange(event.target.value)}
						/>
					)}
					{isCRACOccount() && (
						<div style={{ width: '220px' }}>
							<ConfirmButton
								to="#"
								text="Añadir comentarios"
								disabled={textData?.trim().length === 0}
								onClick={handleAditionalClick}
							/>
						</div>
					)}

					{showAditionalText && (
						<textarea
							id={INPUT_ADITIONAL}
							placeholder="Comentarios Adicionales"
							rows={5}
							value={aditionalData}
							onChange={setText}
						/>
					)}
					<ConfirmButton to={nextScreen} disabled={!canContinue} onClick={handleConfirm} />
				</div>
			</div>
		</div>
	);
};

export default NarrationScreen;
