import { Photo, PhotoSections, PhotoSection, Validation } from '../../../context/interfaces';
import { ReactComponent as CarFrontSide } from 'assets/icons/car_front.svg';
import { ReactComponent as DocumentIcon } from 'assets/icons/ruc.svg';
import {
	carBack,
	license,
	plate,
	carPassengerSide,
	carDriverSide,
	carFront,
	driverLicense,
	cedula,
	other,
} from '../../../context/Utils';
import { PhotoId } from '@connect-assistance/connect-react-components-lib';

export const getSegurosMundialPhotos = () => {
	const type = localStorage.getItem('SinisterType');
	const isLesionadosHomicidios = type === 'Lesiones' || type === 'Homicidios';
	return isLesionadosHomicidios ? segurosMundialPhotosInjuries : segurosMundialPhotos;
};

export const PhotosSegurosMundial = getSegurosMundialPhotos;

export const segurosMundialPhotos: Photo[] = [
	{
		id: PhotoId.USER_LICENSE_COL,
		section: PhotoSection.INJURED_LICENSE,
		placeholder: driverLicense,
		selected: true,
		description: 'Licencia de Conducir',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
		useGallery: true,
	},
	{
		id: PhotoId.VEHICLE_LICENSE_COL,
		section: PhotoSection.INJURED_VEHICLE_LICENSE,
		placeholder: license,
		selected: false,
		description: 'Tarjeta de propiedad del vehículo (frontal)',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
		useGallery: true,
	},
	{
		id: PhotoId.INSURED_DRIVER_LICENSE_BACK,
		section: PhotoSection.INJURED_VEHICLE_LICENSE,
		placeholder: license,
		selected: false,
		description: 'Tarjeta de propiedad del vehículo (trasera)',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
		useGallery: true,
	},
	{
		id: PhotoId.VEHICLE_PLATE_COL,
		section: PhotoSection.INJURED_TABLILLA,
		placeholder: plate,
		selected: true,
		description: 'Placa',
		required: true,
		validations: [Validation.EXTRACT_PLATE],
		validationResults: {},
		sectionId: 'vehicle',
		useGallery: true,
	},
	{
		id: PhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carPassengerSide,
		selected: false,
		description: 'Lateral Derecha del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
		useGallery: true,
	},
	{
		id: PhotoId.INJURED_VEHICLE_DRIVER_SIDE,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carDriverSide,
		selected: false,
		description: 'Lateral Izquierda del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
		useGallery: true,
	},
	{
		id: PhotoId.INJURED_VEHICLE_BACK,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carBack,
		selected: false,
		description: 'Parte Trasera del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
		useGallery: true,
	},
	{
		id: PhotoId.INJURED_VEHICLE_FRONT,
		section: PhotoSection.INJURED_VEH_PHOTOS,
		placeholder: carFront,
		selected: false,
		description: 'Parte Delantera del Vehículo',
		required: true,
		validations: [Validation.HAS_VEHICLE],
		validationResults: {},
		sectionId: 'vehicle',
		useGallery: true,
	},
	{
		id: PhotoId.OTHERS_1,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: cedula,
		selected: true,
		description: 'Foto frontal de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.OTHERS_2,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: license,
		selected: true,
		description: 'Foto del reverso de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'SECURE_CERTIFICATE',
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: license,
		selected: true,
		description:
			'Si el vehículo se encuentra asegurado: certificación original de su compañía de seguros de no reclamación, señalando fecha del siniestro y placa. Su expedición no deberá superar diez (10) días hábiles.',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'OWNER_LATTER',
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: license,
		selected: true,
		description:
			'Si el vehículo no se encuentra asegurado, carta formal del propietario del mismo declarando que a la fecha del accidente no tenía contratada póliza que diera cobertura al mismo.',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'vehicle_video',
		section: PhotoSection.VEHICLE_VIDEO,
		selected: false,
		description: 'Video',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'video',
	},
];

export const segurosMundialPhotoSections: PhotoSections[] = [
	{
		description: 'Exterior del Vehículo',
		srcImage: CarFrontSide,
		sectionId: 'vehicle',
		photosIds: [
			PhotoId.VEHICLE_PLATE_COL,
			PhotoId.INJURED_VEHICLE_PASSENGER_SIDE,
			PhotoId.INJURED_VEHICLE_DRIVER_SIDE,
			PhotoId.INJURED_VEHICLE_BACK,
			PhotoId.INJURED_VEHICLE_FRONT,
			PhotoId.INJURED_VEHICLE,
		],
		required: true,
	},
	{
		description: 'Documentos',
		srcImage: DocumentIcon,
		sectionId: 'documents',
		photosIds: [
			PhotoId.USER_LICENSE_COL,
			PhotoId.VEHICLE_LICENSE_COL,
			PhotoId.INSURED_DRIVER_LICENSE_BACK,
			PhotoId.OTHERS_1,
			PhotoId.OTHERS_2,
			'SECURE_CERTIFICATE',
			'OWNER_LATTER',
		],
		required: true,
	},
];

export const segurosMundialPhotosInjuries: Photo[] = [
	{
		id: PhotoId.OTHERS_1,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: cedula,
		selected: true,
		description: 'Foto frontal de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: PhotoId.OTHERS_2,
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: license,
		selected: true,
		description: 'Foto del reverso de la cédula',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'CLAIM',
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: true,
		description: 'Carta de reclamación formal de los lesionados.',
		required: true,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'MEDICAL_HISTORY',
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: true,
		description: 'Copia o resumen de la historia clínica.',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'INABILITY',
		section: PhotoSection.INJURED_OTHERS_DOCS,
		placeholder: other,
		selected: true,
		description: 'Dictamen de médico legal en el cual se indique la incapacidad y las secuelas',
		required: false,
		validations: [Validation.HAS_DOCUMENT],
		validationResults: {},
		sectionId: 'documents',
	},
	{
		id: 'vehicle_video',
		section: PhotoSection.VEHICLE_VIDEO,
		selected: false,
		description: 'Video',
		required: false,
		validations: [],
		validationResults: {},
		sectionId: 'video',
	},
];

export const segurosMundialPhotoSectionsInjuries: PhotoSections[] = [
	{
		description: 'Documentos',
		srcImage: DocumentIcon,
		sectionId: 'documents',
		photosIds: [PhotoId.OTHERS_1, PhotoId.OTHERS_2, 'CLAIM', 'MEDICAL_HISTOR', 'INABILITY'],
		required: true,
	},
];
