import {
	csmPhotoSections,
	lafisePhotoSections,
	suraPhotoSections,
	qualitasPhotoSections,
	oceanicaPhotoSections,
	assaPhotoSections,
	segurosMundialPhotoSections,
	multinationalPhotoSectionsInjured,
	multinationalPhotoSectionsInsured,
	premierPhotoSectionsInjured,
	premierPhotoSectionsInsured,
	optimaPhotoSectionsInjured,
	optimaPhotoSectionsInsured,
	segurosMundialPhotoSectionsInjuries,
} from 'components/camera/photos';
import {
	ScreenIndexCSM,
	ScreenIndexSura,
	SectionOptions,
	ScreenIndexCRC,
	ScreenIndexWithoutSketchCRC,
	ScreenIndexSegurosMundial,
	ScreenIndexQualitas,
	ScreenIndexMultinational,
	ScreenIndexPREMIER,
	ScreenIndexOPTIMA,
} from 'context/interfaces';

export const BRAND = process.env.REACT_APP_BRAND || '';

const type = localStorage.getItem('ClaimantType');
const SinisterType = localStorage.getItem('SinisterType');
const isLesionadosHomicidios = SinisterType ? ['Lesiones', 'Homicidios'].includes(SinisterType) : false;
export const sections: SectionOptions = {
	csm: csmPhotoSections,
	sura: suraPhotoSections,
	lafise: lafisePhotoSections,
	qualitas: qualitasPhotoSections,
	oceanica: oceanicaPhotoSections,
	assa: assaPhotoSections,
	connect: lafisePhotoSections,
	segurosmundial: isLesionadosHomicidios ? segurosMundialPhotoSectionsInjuries : segurosMundialPhotoSections,
	multinational: type === 'Asegurado' ? multinationalPhotoSectionsInsured : multinationalPhotoSectionsInjured,
	premier: type === 'Asegurado' ? premierPhotoSectionsInsured : premierPhotoSectionsInjured,
	optima: type === 'Asegurado' ? optimaPhotoSectionsInsured : optimaPhotoSectionsInjured,
	comfenalco: segurosMundialPhotoSections,
};

const csmAppConfigRoot = {
	injuredInformation: ScreenIndexCSM.INJURED,
	driverInformation: ScreenIndexCSM.DRIVER,
	vehicleInformation: ScreenIndexCSM.VEHICLE,
	injuredNarration: ScreenIndexCSM.INJURED_NARRATION,
	injuredDamages: ScreenIndexCSM.INSURED_DAMAGES,
	injuredLocationSelect: ScreenIndexCSM.COUNTERPART_LOCATION,
	injuredLocation: ScreenIndexCSM.LOCATION,
	scene: ScreenIndexCSM.SCENE,
	sceneLocation: ScreenIndexCSM.SKETCH,
	photoSection: ScreenIndexCSM.PHOTO_MENU,
	isDeposit: ScreenIndexCSM.IS_DEPOSIT,
	depositForm: ScreenIndexCSM.DEPOSIT_FORM,
	summary: ScreenIndexCSM.SUMMARY,
	location: ScreenIndexCSM.LOCATION,
	survey: ScreenIndexCSM.SURVEY,
};

const multinationalAppConfigRoot = {
	injuredInformation: ScreenIndexMultinational.INJURED,
	driverInformation: ScreenIndexMultinational.DRIVER,
	vehicleInformation: ScreenIndexMultinational.VEHICLE,
	injuredNarration: ScreenIndexMultinational.INJURED_NARRATION,
	injuredDamages: ScreenIndexMultinational.INSURED_DAMAGES,
	injuredLocationSelect: ScreenIndexMultinational.COUNTERPART_LOCATION,
	injuredLocation: ScreenIndexMultinational.LOCATION,
	scene: ScreenIndexMultinational.SCENE,
	sceneLocation: ScreenIndexMultinational.SKETCH,
	photoSection: ScreenIndexMultinational.PHOTO_MENU,
	summary: ScreenIndexMultinational.SUMMARY,
	location: ScreenIndexMultinational.LOCATION,
	survey: ScreenIndexMultinational.SURVEY,
};

const suraIndexConfig = {
	insuredInformation: ScreenIndexSura.INSURED,
	driverInformation: ScreenIndexSura.DRIVER,
	vehicleInformation: ScreenIndexSura.VEHICLE,
	locationSelect: ScreenIndexSura.LOCATION_SELECT,
	location: ScreenIndexSura.LOCATION,
	scene: ScreenIndexSura.SCENE,
	sceneLocation: ScreenIndexSura.SKETCH,
	insuredNarration: ScreenIndexSura.INSURED_NARRATION,
	insuredResponsability: ScreenIndexSura.INSURED_RESPONSABILITY,
	insuredDamages: ScreenIndexSura.INSURED_DAMAGES,
	counterPartInformation: ScreenIndexSura.COUNTERPART_INFO,
	counterPartDamages: ScreenIndexSura.COUNTERPART_DAMAGES,
	photoSection: ScreenIndexSura.PHOTO_MENU,
	summary: ScreenIndexSura.SUMMARY,
	survey: ScreenIndexSura.SURVEY,
};

const crcIndexConfig = {
	insuredInformation: ScreenIndexCRC.INSURED,
	driverInformation: ScreenIndexCRC.DRIVER,
	vehicleInformation: ScreenIndexCRC.VEHICLE,
	locationSelect: ScreenIndexCRC.LOCATION_SELECT,
	location: ScreenIndexCRC.LOCATION,
	scene: ScreenIndexCRC.SCENE,
	sceneLocation: ScreenIndexCRC.SKETCH,
	insuredNarration: ScreenIndexCRC.INSURED_NARRATION,
	insuranceAlert: ScreenIndexCRC.INSURED_ALERT,
	insuredDamages: ScreenIndexCRC.INSURED_DAMAGES,
	isVehicle: ScreenIndexCRC.IS_VEHICLE,
	counterPartInformation: ScreenIndexCRC.COUNTERPART_INFO,
	policivePart: ScreenIndexCRC.POLICIVE_PART,
	photoSection: ScreenIndexCRC.PHOTO_MENU,
	uploadPhoto: ScreenIndexCRC.UPLOAD_PHOTO,
	summary: ScreenIndexCRC.SUMMARY,
	survey: ScreenIndexCRC.SURVEY,
};

const crcIndexConfigWithoutSketch = {
	insuredInformation: ScreenIndexWithoutSketchCRC.INSURED,
	driverInformation: ScreenIndexWithoutSketchCRC.DRIVER,
	vehicleInformation: ScreenIndexWithoutSketchCRC.VEHICLE,
	locationSelect: ScreenIndexWithoutSketchCRC.LOCATION_SELECT,
	location: ScreenIndexWithoutSketchCRC.LOCATION,
	insuredNarration: ScreenIndexWithoutSketchCRC.INSURED_NARRATION,
	insuranceAlert: ScreenIndexWithoutSketchCRC.INSURED_ALERT,
	insuredDamages: ScreenIndexWithoutSketchCRC.INSURED_DAMAGES,
	isVehicle: ScreenIndexWithoutSketchCRC.IS_VEHICLE,
	counterPartInformation: ScreenIndexWithoutSketchCRC.COUNTERPART_INFO,
	policivePart: ScreenIndexWithoutSketchCRC.POLICIVE_PART,
	photoSection: ScreenIndexWithoutSketchCRC.PHOTO_MENU,
	uploadPhoto: ScreenIndexWithoutSketchCRC.UPLOAD_PHOTO,
	summary: ScreenIndexWithoutSketchCRC.SUMMARY,
	survey: ScreenIndexWithoutSketchCRC.SURVEY,
};

const segurosMundialIndexConfig = {
	insuredInformation: ScreenIndexSegurosMundial.INSURED,
	driverInformation: ScreenIndexSegurosMundial.DRIVER,
	vehicleInformation: ScreenIndexSegurosMundial.VEHICLE,
	validation: ScreenIndexSegurosMundial.VALIDATION,
	accidentInformation: ScreenIndexSegurosMundial.ACCIDENT_INFORMATION,
	insuredNarration: ScreenIndexSegurosMundial.INSURED_NARRATION,
	insuredDamages: ScreenIndexSegurosMundial.INSURED_DAMAGES,
	photoSection: ScreenIndexSegurosMundial.PHOTO_MENU,
	uploadPhoto: ScreenIndexSegurosMundial.UPLOAD_PHOTO,
	summary: ScreenIndexSegurosMundial.SUMMARY,
	survey: ScreenIndexPREMIER.SURVEY,
};

const premierAppConfigRoot = {
	injuredInformation: ScreenIndexPREMIER.INJURED,
	driverInformation: ScreenIndexPREMIER.DRIVER,
	vehicleInformation: ScreenIndexPREMIER.VEHICLE,
	injuredNarration: ScreenIndexPREMIER.INJURED_NARRATION,
	injuredDamages: ScreenIndexPREMIER.INSURED_DAMAGES,
	injuredLocationSelect: ScreenIndexPREMIER.COUNTERPART_LOCATION,
	injuredLocation: ScreenIndexPREMIER.LOCATION,
	scene: ScreenIndexPREMIER.SCENE,
	sceneLocation: ScreenIndexPREMIER.SKETCH,
	photoSection: ScreenIndexPREMIER.PHOTO_MENU,
	summary: ScreenIndexPREMIER.SUMMARY,
	location: ScreenIndexPREMIER.LOCATION,
	survey: ScreenIndexPREMIER.SURVEY,
};

const optimaAppConfigRoot = {
	injuredInformation: ScreenIndexOPTIMA.INJURED,
	driverInformation: ScreenIndexOPTIMA.DRIVER,
	vehicleInformation: ScreenIndexOPTIMA.VEHICLE,
	injuredNarration: ScreenIndexOPTIMA.INJURED_NARRATION,
	injuredDamages: ScreenIndexOPTIMA.INSURED_DAMAGES,
	injuredLocationSelect: ScreenIndexOPTIMA.COUNTERPART_LOCATION,
	injuredLocation: ScreenIndexOPTIMA.LOCATION,
	scene: ScreenIndexOPTIMA.SCENE,
	sceneLocation: ScreenIndexOPTIMA.SKETCH,
	photoSection: ScreenIndexOPTIMA.PHOTO_MENU,
	summary: ScreenIndexOPTIMA.SUMMARY,
	location: ScreenIndexOPTIMA.LOCATION,
	survey: ScreenIndexOPTIMA.SURVEY,
};

export const csmSteps: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de perjudicado',
	2: 'Información de conductor',
	3: 'Vehículo perjudicado',
	4: 'Descripción del accidente',
	5: 'Daños del auto',
	6: 'Lugar de inspección',
	7: 'Localización de la inspección',
	8: 'Escenario',
	9: 'Diagrama del accidente',
	10: 'Fotografías',
	11: 'Deposito directo',
	12: 'Depósito directo',
	13: 'Resumen',
	14: 'Encuesta',
};

export const suraSteps: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de asegurado',
	2: 'Informacion conductor',
	3: 'Vehículo asegurado',
	4: 'Lugar de inspección',
	5: 'Lugar del accidente',
	6: 'Escenarios',
	7: 'Diagrama del accidente',
	8: 'Descripción del accidente',
	9: 'Responsabilidad',
	10: 'Daños del auto',
	11: 'Información de contraparte',
	12: 'Daños contraparte',
	13: 'Fotografías',
	14: 'Resumen',
	15: 'Encuesta',
};

export const CRC_STEPS: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de asegurado',
	2: 'Información de conductor',
	3: 'Vehículo asegurado',
	4: 'Lugar de inspección',
	5: 'Contraparte',
	6: 'Información de contraparte',
	7: 'Responsabilidad',
	8: 'Localización de la inspección',
	9: 'Escenario',
	10: 'Diagrama del accidente',
	11: 'Descripción del accidente',
	12: 'Asegurado',
	13: 'Daños del auto',
	14: 'Fotografías',
	15: 'Galería de Fotos',
	16: 'Resumen',
	17: 'Encuesta',
};

export const CRC_QUALITAS: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de asegurado',
	2: 'Información de conductor',
	3: 'Vehículo asegurado',
	4: 'Lugar de inspección',
	5: 'Contraparte',
	6: 'Información de contraparte',
	7: 'Responsabilidad',
	8: 'Localización de la inspección',
	11: 'Descripción del accidente',
	12: 'Asegurado',
	13: 'Daños del auto',
	14: 'Fotografías',
	15: 'Galería de Fotos',
	16: 'Resumen',
	17: 'Encuesta',
};

export const CRC_STEPS_WITHOUT_SKETCH: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de asegurado',
	2: 'Información de conductor',
	3: 'Vehículo asegurado',
	4: 'Lugar de inspección',
	5: 'Contraparte',
	6: 'Información de contraparte',
	7: 'Responsabilidad',
	8: 'Localización de la inspección',
	9: 'Descripción del accidente',
	10: 'Asegurado',
	11: 'Daños del auto',
	12: 'Fotografías',
	13: 'Galería de Fotos',
	14: 'Resumen',
	15: 'Encuesta',
};

export const segurosMundialSteps: { [key: number]: string } = {
	1: 'Información del dueño',
	2: 'Informacion conductor',
	3: 'Datos vehiculo',
	4: 'Validación para el Propietario del Vehículo Afectado',
	5: 'Información del Accidente',
	6: 'Relato breve de los hechos',
	7: 'Diagrama de daños',
	8: 'Fotografías',
	9: 'Fotografías - Documentos',
	10: 'Resumen',
	11: 'Encuesta',
};

export const multinationalSteps: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de',
	2: 'Información de conductor',
	3: 'Vehículo',
	4: 'Descripción del accidente',
	5: 'Daños del auto',
	6: 'Lugar de inspección',
	7: 'Localización de la inspección',
	8: 'Escenario',
	9: 'Diagrama del accidente',
	10: 'Fotografías',
	11: 'Resumen',
	12: 'Encuesta',
};

export const premierSteps: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de',
	2: 'Información de conductor',
	3: 'Vehículo',
	4: 'Descripción del accidente',
	5: 'Daños del auto',
	6: 'Lugar de inspección',
	7: 'Localización de la inspección',
	8: 'Escenario',
	9: 'Diagrama del accidente',
	10: 'Fotografías',
	11: 'Resumen',
	12: 'Encuesta',
};

export const optimaSteps: { [key: number]: string } = {
	0: 'Fotografías',
	1: 'Información de',
	2: 'Información de conductor',
	3: 'Vehículo',
	4: 'Descripción del accidente',
	5: 'Daños del auto',
	6: 'Lugar de inspección',
	7: 'Localización de la inspección',
	8: 'Escenario',
	9: 'Diagrama del accidente',
	10: 'Fotografías',
	11: 'Resumen',
	12: 'Encuesta',
};

export const brandScreenIndexes: any = {
	csm: csmAppConfigRoot,
	sura: suraIndexConfig,
	lafise: crcIndexConfigWithoutSketch,
	oceanica: crcIndexConfigWithoutSketch,
	qualitas: crcIndexConfig,
	assa: crcIndexConfigWithoutSketch,
	segurosmundial: segurosMundialIndexConfig,
	connect: crcIndexConfig,
	multinational: multinationalAppConfigRoot,
	premier: premierAppConfigRoot,
	optima: optimaAppConfigRoot,
	comfenalco: segurosMundialIndexConfig,
};

const steps: any = {
	csm: csmSteps,
	sura: suraSteps,
	lafise: CRC_STEPS_WITHOUT_SKETCH,
	oceanica: CRC_STEPS_WITHOUT_SKETCH,
	qualitas: CRC_QUALITAS,
	assa: CRC_STEPS_WITHOUT_SKETCH,
	segurosmundial: segurosMundialSteps,
	connect: CRC_STEPS,
	multinational: multinationalSteps,
	premier: premierSteps,
	optima: optimaSteps,
	comfenalco: segurosMundialSteps,
};

export const progress: any = {
	csm: ScreenIndexCSM,
	sura: ScreenIndexSura,
	lafise: ScreenIndexWithoutSketchCRC,
	oceanica: ScreenIndexWithoutSketchCRC,
	qualitas: ScreenIndexQualitas,
	assa: ScreenIndexWithoutSketchCRC,
	segurosmundial: ScreenIndexSegurosMundial,
	connect: ScreenIndexCRC,
	multinational: ScreenIndexMultinational,
	premier: ScreenIndexPREMIER,
	optima: ScreenIndexOPTIMA,
	comfenalco: ScreenIndexSegurosMundial,
};

export const progressByBrand = progress[BRAND];

export const stepsByBrand = steps[BRAND];

export const indexByBrand = brandScreenIndexes[BRAND];

export const photoVisitedSections = sections[BRAND].reduce((acc, currentValue) => {
	const sectionId = currentValue.sectionId;
	acc[sectionId] = { visited: false };
	return acc;
}, {} as Record<string, { visited: boolean }>);

export const urlIdRewrite = (currentURL: string, idValue: string): string => {
	return currentURL.replace(':id', idValue);
};
