import React, { useContext, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import { Context } from '../../context/Context';
import { AppContext, ActionType, PhotoSection, Photo, Brands } from '../../context/interfaces';
import imageCompression from 'browser-image-compression';
import { plusIcon, uploadPhoto, cancelIcon, dataUrl2Blob } from '../../context/Utils';
import ProgressBar from '../../components/ProgressBar';
import Loading from '../../components/common/Loading';
import { BRAND } from 'shared/utils';
import '../../assets/styles/screens/photos/UploadPhoto.scss';
import { useParams } from 'react-router';
import { urlIdRewrite } from 'shared/utils';

interface Props {
	to: string;
	screenIndex: number;
}

const blobToFile = (theBlob: Blob, fileName: string): File => {
	const b: any = theBlob;
	//A Blob() is almost a File() - it's just missing the two properties below which we will add
	b.lastModifiedDate = new Date();
	b.name = fileName;

	//Cast to a File() type
	return theBlob as File;
};

export const savePhoto = async (ctx: AppContext, dataUrl: string): Promise<void> => {
	const galleryPhoto: Photo = {
		id: 'insured_gallery',
		section: Brands.SEGUROS_MUNDIAL === BRAND ? PhotoSection.INJURED_OTHERS_DOCS : PhotoSection.OTHERS,
		sectionId: 'others',
		placeholder: '',
		description: '',
		required: false,
		validations: [],
		validationResults: {},
	};

	const nonCompressed = blobToFile(dataUrl2Blob(dataUrl), 'blob');
	const compressedFile = await imageCompression(nonCompressed, {
		maxSizeMB: 1,
		maxWidthOrHeight: 1920,
		useWebWorker: true,
	});
	const dataURI = await imageCompression.getDataUrlFromFile(compressedFile);

	if (dataURI) {
		const awsUrl = await uploadPhoto(
			{ ...galleryPhoto!, dataUrl: dataURI },
			ctx.eventId!,
			ctx.serviceId!,
			ctx.inspectionId
		);
		if (awsUrl) {
			ctx.dispatch({ type: ActionType.ADD_PHOTO_GALLERY, data: awsUrl });
		}
	}
};
export const removePhoto = async (ctx: AppContext, uri: string): Promise<void> => {
	ctx.dispatch({ type: ActionType.DELETE_PHOTO_GALLERY, data: uri });
};

const UploadPhotoScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const { galleryPhotos, SinisterType } = ctx;
	const { id } = useParams<{ id: string }>();
	const inputFileRef = React.createRef<HTMLInputElement>();
	const [isLoading, setIsLoading] = useState(false);

	const handleBtnClick = () => {
		if (inputFileRef.current) inputFileRef.current.click();
	};
	const isLesionadosHomicidios = SinisterType === 'Lesiones' || SinisterType === 'Homicidios';

	const imageHandler = (e: any) => {
		setIsLoading(true);
		if (e.target.files) {
			const dataFiles = [...e.target.files];
			dataFiles.forEach((file) => {
				const reader = new FileReader();
				reader.onload = async (): Promise<void> => {
					if (reader.readyState === 2) {
						if (reader.result) {
							await savePhoto(ctx, reader.result?.toString());
						}
						setIsLoading(false);
					}
				};
				reader.readAsDataURL(file);
			});
			return true;
		}
	};

	const title =
		Brands.SEGUROS_MUNDIAL === BRAND && !isLesionadosHomicidios
			? `
		<ul>
			<li>
				En el caso en que el vehículo haya sido reparado antes de presentar la solicitud de indemnización, se le exigirá presentar las facturas originales que cumplan con los requisitos de la DIAN y se efectuará un peritaje con el fin de realizar el ajuste de los daños y el reconocimiento de los valores pagados.
			</li>
			<li>Informe de accidente, croquis, carta de invitación a reclamar, fallos de tránsito o actas de conciliación.</li>
			<li>Cotización de la reparación del vehículo, detallando las operaciones de mano de obra y repuestos.</li>
			<li>Fotografías del vehículo afectado (generales y de la zona afectada).</li>
		</ul>
	`
			: isLesionadosHomicidios
			? `
		<ul>
			<li>
				Facturas originales de los gastos médicos, quirúrgicos, hospitalarios, etc., no cubiertos por el seguro obligatorio SOAT, FOSYGA ni por ningún sistema de seguridad social.
			</li>
		</ul>
	`
			: 'Adjuntar fotografías tomadas el día del accidente';

	return (
		<>
			<Breadcrumbs currentStep={props.screenIndex} />
			<ProgressBar screenIndex={props.screenIndex} />
			<Header title={title} subtitle="" />
			<div className="uploadPhoto" data-testid="uploadPhoto">
				<div className="uploadZone">
					<input
						ref={inputFileRef}
						type="file"
						name="image-upload"
						id="image-upload"
						accept="image/*"
						multiple={true}
						onChange={imageHandler}
						data-testid="image-upload"
					/>
					<div className="uploadIcon">
						<img src={plusIcon} alt="Subir foto" onClick={handleBtnClick} />
					</div>
					<label className="uploadText" htmlFor="image-upload" data-testid="uploadText">
						Presione para subir imágenes <br />
						desde su galería
						{isLoading && <Loading />}
					</label>

					<div className="gallery-photos">
						{galleryPhotos.length > 0 &&
							galleryPhotos.map((photo) => {
								return (
									<div key={photo} className="image-container" data-testid="image-container">
										<img
											className="remove"
											src={cancelIcon}
											alt="remove icon"
											onClick={() => removePhoto(ctx, photo)}
										/>
										<img className="image" key={photo} src={photo} alt="uploaded pic" />
									</div>
								);
							})}
					</div>
				</div>
				<ConfirmButton to={urlIdRewrite(props.to, id)} />
			</div>
		</>
	);
};

export default UploadPhotoScreen;
