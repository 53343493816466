import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Brands, BreadcrumbSteps } from '../context/interfaces';
import { backBtn, headerLogo } from '../context/Utils';
import WhatsAppButton from './common/WhatsAppButton';
import { BRAND, stepsByBrand } from 'shared/utils';
import '../assets/styles/components/Breadcrumbs.scss';
import { Context } from '../context/Context';
import { AppContext } from '../context/interfaces';

interface BreadcrumbsProps {
	currentStep: BreadcrumbSteps;
}

const Breadcrumbs = (props: BreadcrumbsProps): JSX.Element => {
	const { goBack } = useHistory();
	const context = useContext(Context) as AppContext;
	const { ClaimantType, SinisterType } = context;
	const isMultinationalOrPremier =
		BRAND === Brands.MULTINATIONAL || BRAND === Brands.PREMIER || BRAND === Brands.OPTIMA;
	const whatsAppButton = !isMultinationalOrPremier;
	const currentStep =
		isMultinationalOrPremier && (props.currentStep === 1 || props.currentStep === 3)
			? stepsByBrand[props.currentStep] + ' ' + ClaimantType
			: BRAND === Brands.SEGUROS_MUNDIAL &&
			  props.currentStep === 1 &&
			  (SinisterType === 'Lesiones' || SinisterType === 'Homicidios')
			? 'Datos Reclamante'
			: stepsByBrand[props.currentStep];

	return (
		<>
			{BRAND === Brands.CSM && (
				<div className="header-logo">
					<img src={headerLogo} alt="header-logo" />
				</div>
			)}
			<div className="breadcrumbs-container">
				<div className="breadcrumbs">
					{props.currentStep !== 1 && <img src={backBtn} alt="back button" onClick={goBack} className="backChevron" />}
					<span data-testid="step-label">{currentStep}</span>
					{whatsAppButton && <WhatsAppButton />}
				</div>
			</div>
		</>
	);
};

export default Breadcrumbs;
