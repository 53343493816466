import React, { createContext, useReducer, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import { initPhotos } from '../components/camera/Utils';
import { Action, AppContext, ActionType, IndividualState } from './interfaces';
import { getStateBackup, reducer, saveStateBackup } from './reducer';
import { photoVisitedSections } from 'shared/utils';

export const Context = createContext({});

const [lat, lng] = process.env.REACT_APP_DEFAULT_COORDS?.split(',') || [18.42439, -66.073885]; // if none in env use Plaza Las Américas coords
export const DEFAULT_COORDS = { lat: +lat, lng: +lng };

const initialFieldsState: IndividualState['fields'] = {
	name: '',
	firstName: '',
	lastName: '',
	email: '',
	plate: '',
	make: '',
	model: '',
	year: '',
	color: '',
	phone: '',
	sex: '',
	license: '',
	postalCode: '',
	urbanization: '',
	isOtherDriver: '',
	driverName: '',
	driverLastName: '',
	driverEmail: '',
	driverRelationship: '',
	driverPhone: '',
	driverSex: '',
	driverLicense: '',
	driverPostalCode: '',
	driverUrbanization: '',
	driverMarbete: '',
	driverMileage: '',
	driverDocumentType: '',
	driverDocumentNumber: '',
	driverExpeditionPlace: '',
	driverExpeditionDate: '',
	incidentType: '',
	vin: '',
	documentType: '',
	documentNumber: '',
	expeditionPlace: '',
	expeditionDate: '',
	billingEmail: '',
	address: '',
	insurance: '',
	insuranceName: '',
	pep: '',
	pepLink: '',
	linkType: '',
	anotherLinkType: '',
	insuredRelationship: '',
	typeOfRelationship: '',
	injuries: '',
	department: '',
	city: '',
};

export const cleanState = {
	account: '',
	countryCode: '',
	eventId: null,
	serviceId: null,
	accountId: null,
	inspectionId: null,
	inspectionDate: null,
	location: {
		coords: DEFAULT_COORDS,
		mapSize: { height: null, width: null },
		address: '',
		newLocation: false,
		selectedLocation: null,
		originalCoords: undefined,
		originalAddress: undefined,
		isGPSActive: null,
		isManualLocation: null,
		level1: '',
		level2: '',
		level3: '',
		customLevel1: '',
		customLevel2: '',
		customLevel3: '',
		date: null,
		time: null,
	},
	insured: {
		narration: '',
		narrationAdditional: '',
		alertNarration: '',
		damages: {},
		fields: initialFieldsState,
		report: undefined,
	},
	injured: {
		narration: '',
		narrationAdditional: '',
		alertNarration: '',
		damages: {},
		fields: initialFieldsState,
		report: undefined,
	},
	sketch: {
		icons: [],
		infoWindowShown: false,
		rotateIcon: false,
		iconStatus: [],
		iconId: null,
		html: null,
		dataUrl: null,
		scenePhoto: null,
		sceneType: null,
		draw: { dataUrl: '', points: '' },
	},
	galleryCompleted: false,
	counterpart: {
		damages: {},
		fields: initialFieldsState,
	},
	signature: null,
	responsibility: {
		responsible: null,
		legal: null,
		insuredInitials: '',
	},
	accidentDate: null,
	accidentInfo: null,
	selectedAccidentType: '',
	selectedIsVehicle: null,
	selectedIsFixedObject: null,
	status: 0,
	policivePart: null,
	numberPart: '',
	allPhotos: [],
	galleryPhotos: [],
	selectedIsDeposit: null,
	directDeposit: {
		claimantName: '',
		claimantNumber: '',
		policy: '',
		financialInstitution: '',
		routeNumber: '',
		branchOffice: '',
		personalContact: '',
		phone: '',
		accountType: '',
		signature: '',
	},
	photoSections: photoVisitedSections,
	showInfoPhotos: true,
};

export const AppContextProvider = (props: { state: any; children: any }): JSX.Element => {
	return <Context.Provider value={props.state}>{props.children}</Context.Provider>;
};

const AppState = (props: any): JSX.Element => {
	const dispatch = (action: Action): void => _dispatch(action);

	const data: AppContext = {
		userSessionId: nanoid(),
		initSectionTime: 0,
		dispatch,
		...cleanState,
		photos: initPhotos([]),
		allPhotos: initPhotos([]),
		showInfoPhotos: true,
		incidentType: null,
	};
	const [state, _dispatch] = useReducer(reducer, data);

	const hasHydrated = useRef(false); // used to avoid saving state before first read on page load

	useEffect(() => {
		// Try to rehydrate state from localstorage.
		(async (): Promise<void> => {
			const stateBackup = await getStateBackup();
			dispatch({
				type: ActionType.HYDRATE_STATE,
				data: {
					...stateBackup,
					photos: initPhotos(stateBackup.photos || []),
					allPhotos: initPhotos([]),
				},
			});
			hasHydrated.current = true;
		})();
	}, []);
	useEffect(() => {
		if (hasHydrated.current) {
			saveStateBackup(state);
		}
	}, [state]);
	return <AppContextProvider state={state}>{props.children}</AppContextProvider>;
};

export const { Consumer } = Context;

export default AppState;
