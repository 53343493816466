import React from 'react';
import '../../assets/styles/components/common/Header.scss';

interface Props {
	title?: string;
	subtitle?: string;
}

const Header = ({ title = '', subtitle = '' }: Props): JSX.Element => {
	return (
		<div className="header">
			<div className="text-container">
				<h3 dangerouslySetInnerHTML={{ __html: title }} />
				<p>{subtitle}</p>
			</div>
		</div>
	);
};

export default Header;
