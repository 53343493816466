import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../../context/Context';
import {
	ActionType,
	AppContext,
	CountryCode,
	DispatchFunction,
	Option,
	Brands,
	IndividualStateKey,
	FieldStateKey,
} from '../../../context/interfaces';
import Dropdown from '../../ui/dropdown';
import TextArea from '../../ui/text-area';
import {
	getCurrentCountry,
	getLocationConfig,
	getLocationLevel1,
	getLocationLevel2,
	getLocationLevel3,
} from '../../../context/Utils';
import './styles.scss';

export const updateText = (event: React.ChangeEvent<HTMLTextAreaElement>, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_EXACT_DIRECTION, data: event.target.value });
};

export const updateLevel = (levelType: number, value: string, dispatch: DispatchFunction): void => {
	dispatch({ type: ActionType.SET_LEVEL_LOCATION, data: { levelType: levelType, levelData: value } });
};

export const handleUpdate = (
	stateKey: IndividualStateKey,
	fieldStateKey: FieldStateKey,
	value: string,
	dispatch: DispatchFunction
): void => {
	dispatch({
		type: ActionType.SET_FIELD_VALUE,
		data: { stateKey, fieldStateKey, value },
	});
};

interface Props {
	brand?: string;
	hiddenField?: boolean;
	stateKey?: IndividualStateKey;
	level1?: FieldStateKey;
	level2?: FieldStateKey;
}

const OtherLocationForm = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const { dispatch, location, insured } = ctx;
	const [nameFirstTerritory, setNameFirstTerritory] = useState('');
	const [nameSecondTerritory, setNameSecondTerritory] = useState('');
	const [nameThirdTerritory, setNameThirdTerritory] = useState('');
	const [firstTerritory, setFirstTerritory] = useState(Array<Option>());
	const [secondTerritory, setSecondTerritory] = useState(Array<Option>());
	const [thirdTerritory, setThirdTerritory] = useState(Array<Option>());
	const setText = (event: React.ChangeEvent<HTMLTextAreaElement>): void => updateText(event, dispatch);
	const setLevelNames = (): void => {
		(async (): Promise<void> => {
			const labels = await getLocationConfig();
			setNameFirstTerritory(labels.labelLevel1);
			setNameSecondTerritory(labels.labelLevel2);
			setNameThirdTerritory(labels.labelLevel3);
		})();
	};
	const setLevelsData = (levelType: number, value: string): void => {
		(async (): Promise<void> => {
			switch (levelType) {
				case 1: {
					const location1 = await getLocationLevel1();
					setFirstTerritory(location1.data);
					if (value.length > 0) {
						const location2 = await getLocationLevel2(value);
						setSecondTerritory(location2.data);
						setThirdTerritory(Array<Option>());
						if (props.stateKey && props.level1) {
							handleUpdate(props.stateKey, props.level1, value, dispatch);
						} else {
							updateLevel(1, value, dispatch);
						}
					} else {
						if (props.stateKey) {
							if (insured.fields.department.length > 0) {
								const location2 = await getLocationLevel2(insured.fields.department);
								setSecondTerritory(location2.data);
							}
						} else {
							if (location.level1.length > 0) {
								const location2 = await getLocationLevel2(location.level1);
								setSecondTerritory(location2.data);
							}
							if (location.level2.length > 0 && props?.brand !== Brands.SEGUROS_MUNDIAL) {
								const location3 = await getLocationLevel3(location.level1, location.level2);
								setThirdTerritory(location3.data);
							}
						}
					}
					break;
				}
				case 2: {
					const location2 = await getLocationLevel2(location.level1);
					setSecondTerritory(location2.data);
					if (value.length > 0) {
						if (props?.brand !== Brands.SEGUROS_MUNDIAL) {
							const location3 = await getLocationLevel3(location.level1, value);
							setThirdTerritory(location3.data);
						}
						if (props.stateKey && props.level2) {
							handleUpdate(props.stateKey, props.level2, value, dispatch);
						} else {
							updateLevel(2, value, dispatch);
						}
					}
					break;
				}
				default:
					if (levelType === 3 && value.length > 0) updateLevel(3, value, dispatch);
					break;
			}
		})();
	};

	useEffect(() => {
		// Preload first level and labels
		if (firstTerritory.length === 0 && getCurrentCountry() !== CountryCode.PRI) {
			setLevelNames();
			setLevelsData(1, '');
		}
	});

	return (
		<div className="otherLocation">
			{firstTerritory.length > 0 && (
				<Dropdown
					label={nameFirstTerritory}
					value={props.stateKey ? insured.fields.department : location.level1}
					options={firstTerritory}
					onChangeOption={(event) => setLevelsData(1, event.target.value)}
				/>
			)}
			{firstTerritory.length > 0 && (
				<Dropdown
					label={nameSecondTerritory}
					value={props.stateKey ? insured.fields.city : location.level2}
					options={secondTerritory}
					onChangeOption={(event) => setLevelsData(2, event.target.value)}
				/>
			)}
			{firstTerritory.length > 0 && props?.brand !== Brands.SEGUROS_MUNDIAL && (
				<Dropdown
					label={nameThirdTerritory}
					value={location.level3}
					options={thirdTerritory}
					onChangeOption={(event) => setLevelsData(3, event.target.value)}
				/>
			)}
			{!props.hiddenField && <TextArea label="Dirección exacta" onChange={setText} value={ctx.accidentInfo?.Lugar} />}
		</div>
	);
};

export default OtherLocationForm;
