import { getPhotoCollection, initPhotos } from '../components/camera/Utils';
import { cleanState } from './Context';

import HeliosClient from './Helios';
/* eslint-disable no-case-declarations */
import {
	Action,
	ActionType,
	AppContext,
	Photo,
	IndividualStateKey,
	Responsible,
	PhotoSection,
	Validation,
	Brands,
	CountryCode,
} from './interfaces';
import { fud, colilla, panoramic, fixedObject, deleteImageFromBucket, getCurrentCountry } from './Utils';
import { BRAND, photoVisitedSections } from 'shared/utils';
import { PhotoId } from '@connect-assistance/connect-react-components-lib';
import {
	IconPosition,
	IconProps,
	IconStatus,
} from '@connect-assistance/connect-react-components-lib/dist/components/sketch/interfaces';

export const saveStateBackup = async (state: AppContext): Promise<string> => {
	try {
		const stateBackup = JSON.stringify(state);
		localStorage.setItem('state', stateBackup);

		return stateBackup;
	} catch (error) {
		console.error('Error saving state. Ignoring since its a backup anyways', error);
		return '';
	}
};
export const getStateBackup = async (): Promise<AppContext> => JSON.parse(localStorage.getItem('state') || '{}');

export const addPhoto = (state: AppContext, { photoId, awsUrl }: { photoId: string; awsUrl: string }): AppContext => {
	const photos = [...state.photos];
	const index = photos.findIndex((p: Photo) => p.id === photoId);
	photos[index].awsUrl = awsUrl;
	const newState = { ...state, photos: [...photos] };
	return newState;
};

export const addPhotoGallery = (state: AppContext, awsUrl: string): AppContext => {
	const timeStampId = Math.round(new Date().getTime() / 1000);
	const galleryPhoto: Photo = {
		id: `insured_gallery_${timeStampId.toString()}`,
		section: PhotoSection.OTHERS,
		sectionId: 'others',
		description: `insured_gallery_${timeStampId.toString()}`,
		awsUrl: awsUrl,
		placeholder: '',
		required: false,
		validations: [],
		validationResults: {},
	};
	let photos = [...state.photos];
	const galleryPhotos = [...state.galleryPhotos];
	galleryPhotos.push(awsUrl);

	const lastInsuredIndex = photos.length - 1;
	const photoElements = photos.slice(0, lastInsuredIndex + 1);
	photos = [...photoElements, galleryPhoto];

	const newState = { ...state, photos: [...photos], galleryPhotos: [...galleryPhotos] };
	if (state.inspectionId) {
		new HeliosClient().saveInspection(newState, state.inspectionId);
	}

	return newState;
};

export const deletePhoto = (state: AppContext, id: PhotoId): AppContext => {
	const index = state.photos.findIndex((photo) => photo.id === id);
	if (index === -1) {
		return state;
	}
	const newPhoto = { ...state.photos[index] };
	newPhoto.dataUrl = undefined;
	newPhoto.awsUrl = undefined;
	newPhoto.validationResults = {};
	// TODO:
	// if (photo.id?.indexOf('gallery_') > -1) photos.splice(index, 1);

	const newPhotos = [...state.photos];
	newPhotos[index] = newPhoto;

	return { ...state, photos: newPhotos };
};

export const deletePhotoGallery = (state: AppContext, photo: string): AppContext => {
	const galleryPhotos = [...state.galleryPhotos];
	const photos = [...state.photos];
	const indexGallery = galleryPhotos.findIndex((p: string) => p === photo);
	const indexPhoto = photos.findIndex((p: Photo) => p.awsUrl === photo);
	if (galleryPhotos[indexGallery]) {
		galleryPhotos.splice(indexGallery, 1);
		deleteImageFromBucket({
			eventId: state.eventId!,
			inspectionId: state.inspectionId!,
			imageUrl: photo,
			serviceId: state.serviceId!,
		});
	}
	if (photos[indexPhoto]) photos.splice(indexPhoto, 1);
	return { ...state, photos: [...photos], galleryPhotos: [...galleryPhotos] };
};

export const setSketchIcons = (icons: IconProps[], data: { index: number; position: IconPosition }): IconProps[] => {
	const _icons = [...icons];
	_icons[data.index].position = data.position;
	return _icons;
};

export const setIconStatus = (icons: IconStatus[], data: { id: number; isSelected: boolean }): IconStatus[] => {
	let _icons: IconStatus[] = [];
	if (icons !== undefined) _icons = [...icons];

	if (_icons.length > 0 && _icons[data.id] !== undefined) {
		_icons.map((i) => {
			i.isSelected = false;
			return i.isSelected;
		});
		_icons[data.id].isSelected = data.isSelected;
	} else {
		_icons.push(data);
	}
	return _icons;
};

export const setIconStatusRestore = (icons: IconStatus[]): IconStatus[] => {
	let _icons: IconStatus[] = [];
	if (icons !== undefined) _icons = [...icons];

	if (_icons.length > 0) {
		_icons.map((i) => {
			i.isSelected = false;
			return i.isSelected;
		});
	}
	return _icons;
};

export const setIconId = (data: { id: number; isSelected: boolean }): number | null => {
	if (data.isSelected) return data.id;
	return null;
};

export const removeSketchIcon = (icons: IconProps[], index: number): IconProps[] => {
	const _icons = [...icons];
	_icons.splice(index, 1);
	return _icons;
};

export const removeIconStatus = (icons: IconStatus[], index: number): IconStatus[] => {
	const _icons = [...icons];
	_icons.splice(index, 1);
	return _icons;
};

const addNewPhotoElement = (id: string, elementToInsert: Photo, photos: Photo[]): Photo[] => {
	if (photos.find((p) => p.id === id)) return photos;
	const elementIndex = photos.findIndex((p: Photo) => p.id === id);
	const part1 = photos.slice(0, elementIndex + 1);
	const part2 = photos.slice(elementIndex + 1, photos.length);
	return [...part1, elementToInsert, ...part2];
};

export const setResponsible = (state: AppContext, responsible: Responsible): AppContext => {
	let photos = [...state.photos];
	if (photos.length > 0) {
		const setDocumentInfo = (description: string, icon: string, id: PhotoId, sectionId: string): void => {
			const fudPhoto = {
				id: id,
				section: PhotoSection.COUNTERPARTS_DOCUMENTS,
				placeholder: icon,
				selected: false,
				description,
				required: true,
				validations: [Validation.HAS_DOCUMENT],
				validationResults: {},
				sectionId,
			};
			photos = addNewPhotoElement(PhotoId.COUNTERPART_VEHICLE_DAMAGE_3, fudPhoto, photos);
		};

		const removeFUD = (): void => {
			const index = photos.findIndex((photo) => photo.id === PhotoId.COUNTERPART_FUD);
			if (index === -1) return;
			photos.splice(index, 1);
		};

		const removeBoleta = (): void => {
			const index = photos.findIndex((photo) => photo.id === PhotoId.INSURED_POLICIVE_PART_PA);
			if (index === -1) return;
			photos.splice(index, 1);
		};

		removeFUD();
		removeBoleta();
		switch (responsible) {
			case Responsible.COUNTERPART:
				setDocumentInfo('Foto de FUD', fud, PhotoId.COUNTERPART_FUD, 'counterpart');
				break;
			case Responsible.INSURED:
				break;
			case Responsible.NONE:
				setDocumentInfo('Foto de Boleta de Tránsito', colilla, PhotoId.INSURED_POLICIVE_PART_PA, 'counterpart');
				break;
		}
	}
	return { ...state, photos: [...photos], responsibility: { ...state.responsibility, responsible } };
};

export const setPartPolicive = (state: AppContext, value: boolean): AppContext => {
	let photos = [...state.photos];
	let hasPolicivePart = true;
	if (photos.length > 0) {
		const index = photos.findIndex((photo) => photo.id === PhotoId.INSURED_POLICIVE_PART_CR);
		const found = index !== -1;
		const removePolicivePart = (): void => {
			if (!found) return;
			photos.splice(index, 1);
		};
		const addPolicivePart = (): void => {
			if (found) return;
			const insuredIndex = photos.findIndex((p) => p.id === PhotoId.INSURED_LICENSE_CR);
			const part1 = photos.slice(0, insuredIndex + 1);
			const part2 = photos.slice(insuredIndex + 1, photos.length);
			const policivePartPhoto: Photo = {
				id: PhotoId.INSURED_POLICIVE_PART_CR,
				section: PhotoSection.OTHERS,
				sectionId: 'documents',
				placeholder: colilla,
				selected: false,
				description: 'Foto Parte Tránsito',
				required: true,
				validations: [Validation.HAS_DOCUMENT],
				validationResults: {},
				name: 'traffic_photo_custom_vi',
			};
			photos = [...part1, policivePartPhoto, ...part2];
		};

		if (!value) {
			removePolicivePart();
			hasPolicivePart = false;
		} else {
			addPolicivePart();
		}
	}
	return { ...state, photos: [...photos], policivePart: hasPolicivePart };
};

export const setFixedObjectPhoto = (state: AppContext, value: string): AppContext => {
	const allPhotos = [...state.allPhotos];
	const photos = [...state.photos];
	const isFixedObject = value === 'isFixedObject';

	if (!isFixedObject && photos.length > 0 && allPhotos.length > 0) {
		const index = photos.findIndex((photo) => photo.id === PhotoId.FIXED_OBJECT);
		if (index !== -1) photos.splice(index, 1);
	} else if (photos.length > 0 && allPhotos.length > 0 && !photos.find((p) => p.id === PhotoId.FIXED_OBJECT)) {
		const index = photos.findIndex((photo) => photo.id === PhotoId.INSURED_VEHICLE_DAMAGE_4);
		photos.splice(index + 1, 0, {
			id: PhotoId.FIXED_OBJECT,
			section: PhotoSection.INSURED_VEHICLE,
			placeholder: fixedObject,
			selected: false,
			description: 'Objeto Fijo',
			required: false,
			validations: [],
			validationResults: {},
			sectionId: 'damage',
		});
	}

	return { ...state, photos: [...photos], selectedIsFixedObject: isFixedObject };
};
export const setCounterPartPhotos = (state: AppContext, value: string): AppContext => {
	const allPhotos = [...state.allPhotos];
	const photos = [...state.photos];
	localStorage.setItem('existsCounterpart', value && value === 'isVehicle' ? '2' : '1');

	const isVehicle = value && value === 'isVehicle' ? true : false;
	const allCounterPartIds = [
		'counterpart_vehicle_passenger_side',
		'counterpart_vehicle_driver_side',
		'counterpart_vehicle_back',
		'counterpart_vehicle_front',
		'counterpart_plate',
		'counterpart_license',
		'counterpart_damage_1',
		'counterpart_damage_2',
		'counterpart_damage_3',
		'counterpart_damage_4',
		'counterpart_technical_review',
		'counterpart_circulation_rights',
	];
	if (photos.length > 0 && allPhotos.length > 0) {
		const index = photos.findIndex((photo) => photo.id === 'counterpart_vehicle_passenger_side');
		const removeCounterPart = (): void => {
			if (index === -1) return;
			photos.splice(index, allCounterPartIds.length);
		};
		removeCounterPart();
	}
	return {
		...state,
		photos: [...photos],
		selectedIsVehicle: isVehicle,
		selectedIsFixedObject: null,
	};
};

export const setPhotoList = (state: AppContext, photos: Photo[]): AppContext => {
	return { ...state, photos: [...photos] };
};

export const setLevelLocation = (state: AppContext, data: any): AppContext => {
	const currentlocation = state.location;
	if (data.levelType === 1) currentlocation.level1 = data.levelData;
	if (data.levelType === 2) currentlocation.level2 = data.levelData;
	if (data.levelType === 3) currentlocation.level3 = data.levelData;
	return { ...state, location: currentlocation };
};

export const reducer = (state: AppContext, action: Action): AppContext => {
	switch (action.type) {
		// Event
		case ActionType.SET_EVENT:
			let newState: any;
			const {
				eventId,
				insured,
				counterpart,
				serviceId,
				inspectionId,
				inspectionDate,
				responsibility,
				photos,
				accountId,
				signature,
				location,
				injured,
				accidentDate,
				accidentInfo,
				selectedAccidentType,
				selectedIsVehicle,
				policivePart,
				numberPart,
				galleryPhotos,
				selectedIsDeposit,
				directDeposit,
				ClaimantType,
				SinisterType,
			} = action.data;

			if (state.eventId !== eventId)
				newState = { ...cleanState, photos: [...initPhotos([])], dispatch: state.dispatch };
			else newState = { ...state };
			const newPhotos = photos?.length > 0 ? photos : initPhotos([]);
			const newGalleryPhotos = galleryPhotos?.length > 0 ? galleryPhotos : [];
			const returnObj = {
				...newState,
				eventId,
				accountId,
				serviceId,
				inspectionId,
				inspectionDate,
				location: { ...newState.location, ...location },
				insured: {
					...newState.insured,
					fields: insured.fields,
					narration: insured.narration,
					narrationAdditional: insured.narrationAdditional,
					damages: insured.damages,
					alertNarration: insured.alertNarration,
					report: insured.report,
				},
				counterpart: { ...newState.counterpart, fields: counterpart.fields, damages: counterpart.damages },
				responsibility: { ...newState.responsibility, ...responsibility },
				signature: signature,
				photos: [...newPhotos],
				injured,
				accidentDate,
				accidentInfo,
				allPhotos: initPhotos([]),
				selectedAccidentType,
				selectedIsVehicle,
				policivePart,
				numberPart,
				galleryPhotos: newGalleryPhotos,
				selectedIsDeposit,
				directDeposit: {
					...directDeposit,
				},
				photoSections: { ...photoVisitedSections },
				ClaimantType,
				SinisterType,
			};

			return returnObj;
		case ActionType.SET_FIELD_VALUE: {
			const { data }: Action<{ stateKey: IndividualStateKey; fieldStateKey: string; value: string }> = action;
			if (!data) {
				return state;
			}
			return Object.assign({}, state, {
				[data.stateKey]: {
					...state[data.stateKey],
					fields: { ...state[data.stateKey].fields, [data.fieldStateKey]: data.value },
				},
			});
		}
		// Photos
		case ActionType.ADD_PHOTO:
			return { ...addPhoto(state, action.data) };
		case ActionType.POPULATE_PHOTOS:
			return { ...state, photos: [...action.data] };
		case ActionType.DELETE_PHOTO:
			return { ...deletePhoto(state, action.data) };
		case ActionType.ADD_PHOTO_GALLERY:
			return { ...addPhotoGallery(state, action.data) };
		case ActionType.DELETE_PHOTO_GALLERY:
			return { ...deletePhotoGallery(state, action.data) };
		case ActionType.UPDATE_PHOTO_LIST:
			return { ...setPhotoList(state, action.data) };
		case ActionType.SET_COORDS:
			return { ...state, location: { ...state.location, coords: action.data, newLocation: true } };
		case ActionType.SET_ADDRESS:
			return { ...state, location: { ...state.location, address: action.data } };
		case ActionType.SET_LOCATION:
			const {
				data: { key: selectedLocation, isSelected },
			} = action;
			let photosState = [...state.photos];

			const currentAddress =
				state.location.originalAddress === undefined ? state.location.address : state.location.originalAddress;

			if (BRAND === Brands.COMFENALCO || BRAND === Brands.SEGUROS_MUNDIAL) {
				photosState = state.photos.filter((photo) => photo.id !== PhotoId.PANORAMIC);
			}
			if (!['inspection', 'default'].includes(selectedLocation)) {
				photosState = state.photos.filter(
					(photo) => ![PhotoId.INJURED_VEHICLE, PhotoId.PANORAMIC].includes(photo.id as PhotoId)
				);
			} else {
				const photoId =
					BRAND === Brands.CSM ||
					BRAND === Brands.MULTINATIONAL ||
					BRAND === Brands.PREMIER ||
					BRAND === Brands.OPTIMA ||
					BRAND === Brands.COMFENALCO ||
					BRAND === Brands.SEGUROS_MUNDIAL
						? PhotoId.INJURED_VEHICLE
						: PhotoId.PANORAMIC;

				const panoramicData = {
					id: photoId,
					section:
						BRAND === Brands.CSM ||
						BRAND === Brands.MULTINATIONAL ||
						BRAND === Brands.PREMIER ||
						BRAND === Brands.OPTIMA ||
						BRAND === Brands.COMFENALCO ||
						BRAND === Brands.SEGUROS_MUNDIAL
							? PhotoSection.INJURED_VEH_PANORAMIC
							: getCurrentCountry() === CountryCode.CRC
							? PhotoSection.PANORAMIC
							: PhotoSection.INSURED_VEH_PANORAMIC,
					placeholder: panoramic,
					awsUrl: '',
					dataUrl: '',
					selected: false,
					description: 'Panorámica',
					required: true,
					validations: [],
					validationResults: {},
					sectionId: 'vehicle',
				};
				const panoramicElementExists = photosState.find((photo) =>
					[PhotoId.INJURED_VEHICLE, PhotoId.PANORAMIC].includes(photo.id as PhotoId)
				);
				photosState = !panoramicElementExists
					? (photosState = addNewPhotoElement(photoId, panoramicData, getPhotoCollection(BRAND)))
					: photosState;
			}
			return {
				...state,
				location: {
					...state.location,
					newLocation: false,
					selectedLocation,
					isSelected: isSelected,
					originalCoords:
						state.location.originalCoords === undefined ? state.location.coords : state.location.originalCoords,
					originalAddress: currentAddress,
					isManualLocation: false,
				},
				photos: photosState,
				accidentInfo: {
					...state.accidentInfo,
					Lugar: currentAddress,
				},
			};
		case ActionType.SET_EXACT_DIRECTION:
			return {
				...state,
				accidentInfo: {
					...state.accidentInfo,
					Lugar: action.data,
				},
			};
		case ActionType.SET_LEVEL_LOCATION:
			return { ...setLevelLocation(state, action.data) };
		case ActionType.SET_ACCIDENT_TYPE:
			return {
				...state,
				selectedAccidentType: action.data,
			};
		case ActionType.SET_INCIDENT_TYPE:
			return {
				...state,
				incidentType: action.data,
			};
		case ActionType.SET_IS_VEHICLE:
			let _state = state;
			if (action.data.key === 'isVehicle') _state = setFixedObjectPhoto(state, action.data.key);
			return {
				...setCounterPartPhotos(_state, action.data.key),
				typeIsVehicle: action.data.key,
				selectedIsVehicle: action.data.isSelected,
			};
		case ActionType.SET_IS_FIXED_OBJECT:
			return { ...setFixedObjectPhoto(state, action.data) };
		case ActionType.SET_IS_DEPOSIT:
			return {
				...state,
				selectedIsDeposit: action.data === 'isDeposit',
			};
		case ActionType.SET_DIRECT_DEPOSIT:
			return { ...state, directDeposit: action.data };
		case ActionType.SET_DEPOSIT_ACCOUNT_TYPE:
			return { ...state, directDeposit: { ...state.directDeposit, accountType: action.data } };
		case ActionType.SET_DEPOSIT_SIGNATURE:
			return { ...state, directDeposit: { ...state.directDeposit, signature: action.data } };
		case ActionType.SET_NARRATION_TEXT:
			return {
				...state,
				insured: {
					...state.insured,
					narration: action.data.key === IndividualStateKey.INSURED ? action.data.value : state?.insured?.narration,
				},
				injured: {
					...state.injured,
					narration: action.data.key === IndividualStateKey.INJURED ? action.data.value : state?.injured?.narration,
				},
			};
		case ActionType.SET_NARRATION_ADITIONAL_TEXT:
			return {
				...state,
				insured: {
					...state.insured,
					narrationAdditional: action.data.value,
				},
			};
		case ActionType.SET_ALERT_NARRATION_TEXT:
			return {
				...state,
				insured: {
					...state.insured,
					alertNarration:
						action.data.key === IndividualStateKey.INSURED ? action.data.value : state?.insured?.alertNarration,
				},
			};
		case ActionType.SET_DAMAGE:
			const { data }: Action<{ stateKey: IndividualStateKey; areaId: number; damageTypes: string[] }> = action;
			if (!data) {
				return state;
			}
			if (action.data.damageTypes.length === 0) {
				const damages = { ...state[data.stateKey].damages };
				delete damages[data.areaId];
				return { ...state, [data.stateKey]: { ...state[data.stateKey], damages } };
			} else {
				const damages = { ...state[data.stateKey].damages };
				damages[data.areaId] = data.damageTypes;
				return { ...state, [data.stateKey]: { ...state[data.stateKey], damages } };
			}
		// Summary > Responsibility
		case ActionType.SET_RESPONSIBLE:
			return { ...setResponsible(state, action.data) };
		case ActionType.SET_POLICIVE_PART:
			return { ...setPartPolicive(state, action.data), typePolicivePart: action.data ? 'Si' : 'No' };
		case ActionType.SET_NUMBER_PART:
			return { ...state, numberPart: action.data };
		case ActionType.SET_LEGAL_ASSISTANCE:
			return { ...state, responsibility: { ...state.responsibility, legal: action.data } };
		case ActionType.SET_INSURED_INITIALS:
			return { ...state, responsibility: { ...state.responsibility, insuredInitials: action.data } };
		case ActionType.SET_SKETCH:
			return { ...state, sketch: { ...state.sketch, ...action.data } };
		case ActionType.SET_SIGNATURE:
			return { ...state, signature: action.data };
		case ActionType.HYDRATE_STATE:
			return { ...state, ...action.data };
		case ActionType.SET_GPS_IS_ACTIVE:
			return {
				...state,
				location: {
					...state.location,
					isGPSActive: action.data,
				},
			};
		case ActionType.SET_MANUAL_LOCATION:
			return {
				...state,
				location: {
					...state.location,
					isManualLocation: action.data,
				},
			};
		case ActionType.SET_SCENE_PHOTO:
			return {
				...state,
				sketch: {
					...state.sketch,
					scenePhoto: (action.data?.photo ? action.data.photo : action.data?.icon) || null,
					sceneType: action.data?.value || null,
				},
			};
		case ActionType.SET_TIME:
			return { ...state, location: { ...state.location, time: action.data } };
		case ActionType.SET_DATE:
			return { ...state, location: { ...state.location, date: action.data } };
		case ActionType.UPDATE_VISITED_SECTION:
			const { sectionId, value } = action.data;
			return { ...state, photoSections: { ...state.photoSections, [sectionId]: { visited: value } } };
		case ActionType.SET_LOCATION_ZONES:
			return {
				...state,
				location: {
					...state.location,
					customLevel1: action.data.level1,
					customLevel2: action.data.level2,
					customLevel3: action.data.level3,
				},
			};
		case ActionType.SET_SHOW_INFO_PHOTOS:
			return { ...state, showInfoPhotos: action.data.value };
		case ActionType.SET_TRANSIT_REPORT_TEXT:
			return {
				...state,
				insured: {
					...state.insured,
					report: action.data,
				},
			};
		case ActionType.SET_INJURIES:
			return {
				...state,
				insured: {
					...state.insured,
					injuries: action.data,
				},
			};
		default:
			return state;
	}
};
