import React, { useContext } from 'react';
import { Context } from '../../context/Context';
import { WALogoBlack } from '../../context/Utils';
import { AppContext } from '../../context/interfaces';
import '../../assets/styles/components/common/WhatsAppButton.scss';

interface BrandTypes {
	[key: string]: string;
}

export const brands: BrandTypes = {
	csm: 'Cooperativa Seguros Múltiples',
	sura: 'Sura',
	lafise: 'Lafise',
	oceanica: 'Oceánica',
	qualitas: 'Qualitas',
	segurosmundial: 'Seguros Mundial',
	premier: 'Premier',
	comfenalco: 'Seguros Mundial',
	optima: 'Optima',
};

const WhatsAppButton = (): JSX.Element => {
	const phoneNumber = process.env.REACT_APP_HELP_PHONE || '';
	const { eventId } = useContext(Context) as AppContext;
	const brand: string = process.env.REACT_APP_BRAND?.toLocaleLowerCase() || '';
	const body = `¡Saludos! Necesito ayuda para poder continuar con mi Inspector Virtual ${brands[brand]}. Mi número de expediente asignado es ${eventId}`;
	return (
		<a
			className="fab"
			target="_blank"
			rel="noopener noreferrer"
			href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${body}`}
		>
			<img src={WALogoBlack} alt="wa-logo" />
		</a>
	);
};

export default WhatsAppButton;
