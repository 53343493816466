import { LocationSearch, Map } from '@connect-assistance/connect-react-components-lib';
import { useLoadScript } from '@react-google-maps/api';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import DatePicker from '../../components/common/DatePicker';
import Header from '../../components/common/Header';
import TimePicker from '../../components/common/TimePicker';
import OptionData from '../../components/information/OptionData';
import { reverseGeocode, reverseGeoArea } from '@connect-assistance/connect-react-components-lib';
import OtherLocationForm from '../../components/location/other-location';
import { updateLatLng } from '../../components/location/Utils';
import ProgressBar from '../../components/ProgressBar';
import { Context } from '../../context/Context';
import HeliosClient from '../../context/Helios';
import { ActionType, AppContext, Brands, LocationIndex } from '../../context/interfaces';
import { markerIcon, resetDate } from '../../context/Utils';
import { completeSectionGTM, initializeSectionGTM } from '../../shared/utils/gooogleTagManager';
import { BRAND, urlIdRewrite } from 'shared/utils';
import '../../assets/styles/screens/location/Location.scss';
import '../../assets/styles/components/location/Map.scss';
import { format, set as setDate } from 'date-fns';
import RoundButton from '../../components/RoundButton';

import { useQuery } from '../Utils';
const LIBRARIES = ['places'];

interface Props {
	to: string;
	locationIndex: LocationIndex; // set location
	screenIndex: any;
}

const LocationScreen = (props: Props): JSX.Element => {
	const ctx = useContext(Context) as AppContext;
	const { inspectionDate, location, insured, dispatch } = ctx;
	const locationPath = useLocation();
	const history = useHistory();
	const { id } = useParams<{ id: string }>();
	const [initLoadGT, setInitLoadGT] = useState(true);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS_KEY,
		libraries: LIBRARIES,
	});
	const search = useQuery();
	const searchOpen = search.get('search') === 'true' || false;

	const validateDate = (): boolean => {
		let valid = true;
		if (inspectionDate && location.date) {
			const resetInspectionDate = resetDate(inspectionDate);
			const resetLocationDate = resetDate(location.date);
			valid = resetLocationDate <= resetInspectionDate;
		}

		return valid;
	};

	const validateSegurosMundial = (): boolean => {
		return !!location.level1 && !!location.level2 && !!location.date && !!location.time && validateDate();
	};

	const canContinueBrands =
		[Brands.OCEANICA, Brands.LAFISE, Brands.QUALITAS].includes(BRAND as Brands) && location.selectedLocation === 'other'
			? location.selectedLocation &&
			  location.selectedLocation.length > 0 &&
			  location.isSelected === true &&
			  location.date &&
			  location.time &&
			  validateDate()
			: location.selectedLocation && location.selectedLocation.length > 0 && location.isSelected === true;

	const canContinue = BRAND === Brands.SEGUROS_MUNDIAL ? validateSegurosMundial() : canContinueBrands;

	const handleConfirm = (): void => {
		ctx.dispatch({
			type: ActionType.SET_GPS_IS_ACTIVE,
			data: props.locationIndex === LocationIndex.LOCATION_MAP,
		});
		completeSectionGTM(ctx, locationPath.pathname);
		new HeliosClient().saveInspection(ctx, id);
	};

	const handleDate = (date: Date): void => {
		const value = format(date, 'yyyy-MM-dd');

		ctx.dispatch({
			type: ActionType.SET_DATE,
			data: value,
		});
	};

	const handleTime = (time: Date): void => {
		const value = format(time, 'HH:mm');

		ctx.dispatch({
			type: ActionType.SET_TIME,
			data: value,
		});
	};

	const handleTransitReport = (value: boolean): void => {
		ctx.dispatch({
			type: ActionType.SET_TRANSIT_REPORT_TEXT,
			data: value,
		});
	};

	const onLocationRetrieved = (lat: number, lng: number): void => {
		updateLatLng(
			{
				latLng: {
					lat: (): number => lat,
					lng: (): number => lng,
				},
			},
			dispatch,
			reverseGeocode,
			reverseGeoArea
		);
	};

	const handleOtherLocation = (): void => {
		dispatch({
			type: ActionType.SET_MANUAL_LOCATION,
			data: true,
		});
		history.push('/' + id + '/other-location');
	};

	let date;
	if (location?.date) {
		const [year, month, day] = String(location?.date).split('-');
		date = setDate(new Date(), { year: +year, month: +month - 1, date: +day });
	} else if (inspectionDate) {
		date = inspectionDate;
	} else {
		date = new Date();
	}

	useEffect(() => {
		if (initLoadGT) {
			initializeSectionGTM(ctx, locationPath.pathname);
			setInitLoadGT(false);
		}
	}, [initLoadGT, ctx, locationPath.pathname]);

	return (
		<div className="location">
			{!searchOpen && <Breadcrumbs currentStep={props.screenIndex} />}
			<ProgressBar screenIndex={props.screenIndex} />

			{props.locationIndex === LocationIndex.SELECT_LOCATION && (
				<>
					<Header title="Selecciona el lugar donde se está realizando la inspección" />
					<OptionData locationIndex={props.locationIndex} />
				</>
			)}

			{props.locationIndex === LocationIndex.INSPECT_SITE && (
				<>
					<Header title="¿Realiza su inspección en el lugar del accidente?" />
					<OptionData locationIndex={props.locationIndex} />
					{[Brands.OCEANICA, Brands.LAFISE, Brands.QUALITAS, Brands.ASSA, Brands.CONNECT].includes(BRAND as Brands) &&
						location.selectedLocation === 'other' && (
							<div className="location__accidentDate">
								<Header title="Fecha del accidente" />
								<div className="location__date">
									<DatePicker value={date} onChange={handleDate} maxDate={inspectionDate} />
								</div>
								<div className="location__time">
									<TimePicker value={location.time} onChange={handleTime} />
								</div>
							</div>
						)}
				</>
			)}

			{props.locationIndex === LocationIndex.OTHER_LOCATION && Brands.SEGUROS_MUNDIAL === BRAND ? (
				<>
					<Header title="Ingrese la dirección exacta donde sucedieron los hechos" subtitle="" />
					<OtherLocationForm brand={BRAND} />
					<div className="location__accidentDate" style={{ marginBottom: '0' }}>
						<Header title="Fecha del accidente" />
						<div className="location__date">
							<DatePicker value={date} onChange={handleDate} maxDate={inspectionDate} />
						</div>
						<div className="location__time">
							<TimePicker value={location.time} onChange={handleTime} />
						</div>
						<div style={{ margin: '1em' }} className="responsibility-screen">
							<p>¿Se levantó informe de tránsito?</p>
							<div className="legal-assistance-options">
								<RoundButton checked={!!insured.report} onClick={(): void => handleTransitReport(true)}>
									Sí
								</RoundButton>
								<RoundButton checked={!insured.report} onClick={(): void => handleTransitReport(false)}>
									No
								</RoundButton>
							</div>
						</div>
					</div>
				</>
			) : (
				props.locationIndex === LocationIndex.OTHER_LOCATION && (
					<>
						<Header title="Ingrese la dirección exacta donde sucedió la inspección" subtitle="" />
						<OtherLocationForm />
					</>
				)
			)}

			{!searchOpen && props.locationIndex === LocationIndex.LOCATION_MAP && (
				<Header title="Mueve el mapa para ubicar el sitio de la inspección" />
			)}
			{!searchOpen && props.locationIndex === LocationIndex.LOCATION_MAP && (
				<Map
					gmapsLoaded={isLoaded}
					location={ctx.location}
					markerIcon={markerIcon}
					onLocationRetrieved={onLocationRetrieved}
					onOtherLocationClick={handleOtherLocation}
				/>
			)}
			{props.locationIndex === LocationIndex.LOCATION_MAP && (
				<LocationSearch
					currentAddress={ctx.location.address}
					searchOpen={searchOpen}
					gmapsLoaded={isLoaded}
					onLocationRetrieved={onLocationRetrieved}
					onFocus={() => history.push('/' + id + '/location?search=true')}
					onPlaceChanged={() => history.goBack()}
				/>
			)}
			{!searchOpen &&
				(props.locationIndex === LocationIndex.LOCATION_MAP ? (
					<ConfirmButton to={urlIdRewrite(props.to, id)} onClick={handleConfirm} />
				) : (
					<>
						<ConfirmButton to={urlIdRewrite(props.to, id)} disabled={!canContinue} onClick={handleConfirm} />
					</>
				))}
		</div>
	);
};

export default LocationScreen;
