import React, { useContext } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import ConfirmButton from '../../components/common/ConfirmButton';
import Header from '../../components/common/Header';
import Signature from '../../components/signature/Signature';
import { Context } from '../../context/Context';
import { AppContext, PhotoSection, Brands, IndividualStateKey } from '../../context/interfaces';
import { car, pinIcon } from '../../context/Utils';
import ProgressBar from '../../components/ProgressBar';
import SectionDetail from '../../customHooks/summary/sectionDetail';
import { BRAND, urlIdRewrite } from 'shared/utils';

import '../../assets/styles/screens/summary/Summary.scss';
import { useParams } from 'react-router';

interface SummaryProps {
	to: string;
	screenIndex: number;
}

const SummaryScreen = ({ to, screenIndex }: SummaryProps): JSX.Element => {
	const context = useContext(Context) as AppContext;
	const { photos, location, signature, ClaimantType } = context;
	const { originalAddress: address } = location;
	const { id } = useParams<{ id: string }>();
	const existsCounterpart = localStorage.getItem('existsCounterpart')
		? String(localStorage.getItem('existsCounterpart'))
		: '1';

	const awsUrlsByCategory: { [key in PhotoSection]: string[] } = {
		[PhotoSection.PANORAMIC]: [],
		[PhotoSection.INSURED_VEHICLE]: [],
		[PhotoSection.INSURED_DOCUMENTS]: [],
		[PhotoSection.COUNTERPARTS]: [],
		[PhotoSection.COUNTERPARTS_DOCUMENTS]: [],
		[PhotoSection.OTHERS]: [],
		[PhotoSection.INSURED_LICENSE]: [],
		[PhotoSection.INSURED_VEHICLE_LICENSE]: [],
		[PhotoSection.INSURED_ODOMETER]: [],
		[PhotoSection.INSURED_MARBETE]: [],
		[PhotoSection.INSURED_VIN]: [],
		[PhotoSection.INSURED_TABLILLA]: [],
		[PhotoSection.INSURED_VEH_PHOTOS]: [],
		[PhotoSection.INSURED_VEH_DAMAGE_PHOTOS]: [],
		[PhotoSection.INSURED_VEH_PANORAMIC]: [],
		[PhotoSection.INSURED_OTHERS_DOCS]: [],
		[PhotoSection.INJURED_LICENSE]: [],
		[PhotoSection.INJURED_VEHICLE_LICENSE]: [],
		[PhotoSection.INJURED_ODOMETER]: [],
		[PhotoSection.INJURED_MARBETE]: [],
		[PhotoSection.INJURED_VIN]: [],
		[PhotoSection.INJURED_TABLILLA]: [],
		[PhotoSection.INJURED_VEH_PHOTOS]: [],
		[PhotoSection.INJURED_VEH_DAMAGE_PHOTOS]: [],
		[PhotoSection.INJURED_VEH_PANORAMIC]: [],
		[PhotoSection.INJURED_OTHERS_DOCS]: [],
		[PhotoSection.VEHICLE_VIDEO]: [],
		[PhotoSection.INSURED_VEHICLE_VIDEO]: [],
	};
	photos.forEach((p) => {
		if (p.awsUrl) {
			awsUrlsByCategory[p.section].push(p.awsUrl || '');
		}
	});

	const canContinue = !!signature;

	const stateFlowKey =
		Brands.CSM === BRAND
			? IndividualStateKey.INJURED
			: Brands.MULTINATIONAL === BRAND || Brands.PREMIER === BRAND || Brands.OPTIMA === BRAND
			? ClaimantType === 'Asegurado'
				? IndividualStateKey.INSURED
				: IndividualStateKey.INJURED
			: IndividualStateKey.INSURED;
	return (
		<div className="summary-screen">
			<Breadcrumbs currentStep={screenIndex} />
			<ProgressBar screenIndex={screenIndex} />
			<Header title="Resumen de la inspección" subtitle="" />
			<div className="container">
				<div className="summary-row">
					<img src={pinIcon} className="icon" alt="" />
					<div className="description">
						<div>Ubicación de accidente</div>
						<div className="subtext">{address}</div>
					</div>
				</div>
				<div className="summary-row">
					<img src={car} className="icon" alt="" />
					<div className="description">
						<div>
							Vehículos en accidente:
							{existsCounterpart}
						</div>
					</div>
				</div>
				<SectionDetail awsUrlsByCategory={awsUrlsByCategory} stateKey={stateFlowKey} />
				<h1>Firma</h1>
				<Signature />
			</div>
			<ConfirmButton to={urlIdRewrite(to, id)} disabled={!canContinue} />
		</div>
	);
};

export default SummaryScreen;
